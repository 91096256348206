const staticParams = [
  'offset',
  'pageSize',
  'keyword',
  'yearFrom',
  'yearTo',
  'eindbodFrom',
  'eindbodTo',
  'dateFrom',
  'dateTo',
  'sortOrder',
  'sortColumn',
  'manufacturer[1]',
  'model_type[1]'
]
export default function() {
  const queryArrayResolver = (data: any) => {
    for (const key in data) {
      if (!staticParams.includes(key)) {
        if (typeof data[key] === 'string') {
          const newArr = data[key].split()
          data = Object.assign({}, data, { [key]: newArr })
        }
      }
    }
    return data
  }
  return {
    queryArrayResolver
  }
}
