import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f67e8fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-body" }
const _hoisted_4 = {
  key: 0,
  class: "modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.type, "modal-backdrop"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", _hoisted_2, [
        (_ctx.type !== 'add-result' && _ctx.type !== 'remove')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "btn-close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close', 'updatePage')))
            }, " x "))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("section", _hoisted_3, [
        _renderSlot(_ctx.$slots, "body", {}, undefined, true)
      ]),
      (_ctx.type === 'details')
        ? (_openBlock(), _createElementBlock("footer", _hoisted_4, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}